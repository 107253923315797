import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          attrs: {
            "after-close": _vm.afterModalClose,
            "destroy-on-close": "",
            "title": "Create New Fixed Asset Register"
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submitModal);
                  }
                }
              }, [_vm._v(" Create ")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v;
            },
            expression: "visible"
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "rules": "required",
            "data-source": _vm.listFinancialYear,
            "source": "year",
            "label": "Financial Year"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("year", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Country",
            "reference": "identities.applications.contexts",
            "custom-query": "app=fas&entityLevel=country",
            "source": "entityLevelCode",
            "source-label": "entityLevelName"
          },
          on: {
            "change": _vm.onNationChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Facility",
            "rules": "required",
            "data-source": _vm.listFacilities,
            "source": "entityId",
            "source-label": "entityLevelName"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("entityId", $event);
            }
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 24
          }
        }, [_c("span", {
          staticClass: "neutral-10--text"
        }, [_vm._v("Sitemap")]), _c("upload-input", {
          attrs: {
            "form-item": "",
            "upload-type": "multiFile",
            "action": _vm.actionUrl,
            "file-list": _vm.fileList,
            "label": "SitemapUrl",
            "confirm-remove-content": "Are you sure that you want to delete the selected attachment?",
            "max-file-size": 10
          },
          on: {
            "change": _vm.onUpload
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "CreateFixedAssetRegister",
  inject: ["resourceProps"],
  data() {
    const [assetRegisterProps, assetRegisterGroupProps] = this.resourceProps;
    return {
      apiUrl: apiUrl$1,
      assetRegisterProps,
      assetRegisterGroupProps,
      visible: false,
      listFinancialYear: [],
      listFacilities: [],
      actionUrl: apiUrl$1 + "/files/images",
      fileList: []
    };
  },
  mounted() {
    this.visible = true;
    this.getListFinancialYear();
  },
  methods: {
    afterModalClose() {
      this.$router.push(this.assetRegisterProps.redirectRoute);
    },
    cancel() {
      this.visible = false;
    },
    async getListFinancialYear() {
      const { data } = await this.axios.get(`${this.apiUrl}/fas/financials`);
      this.listFinancialYear = data.results;
    },
    async getListFacilities(countryCode) {
      const { data } = await this.axios.get(`${this.apiUrl}/identities/applications/contexts?app=fas&entityLevel=facility&entityParentCode=${countryCode}`);
      this.listFacilities = data;
    },
    onNationChange(value) {
      this.getListFacilities(value);
    },
    onUpload(res) {
      this.storeValue("siteMapUrl", res);
    },
    storeValue(resourceKey, value) {
      const createForm = this.createForm;
      this.createForm = {
        ...createForm,
        [resourceKey]: value
      };
      this.assetRegisterProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    async submitModal() {
      await this.assetRegisterProps.crud.submitEntity("create");
      await this.assetRegisterGroupProps.crud.fetchList();
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateFixedAssetRegister = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", {
    attrs: {
      "name": "identities.applications.contexts",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": ["fas.assetregisters", "fas.assetregisters.getlistgroup"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/fixed-asset-register"]
    }
  }, [_c("create-fixed-asset-register")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { CreateFixedAssetRegister },
  data() {
    return {
      CreateFixedAssetRegister,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
